import React from "react";
import { graphql } from "gatsby";
import { debounce } from "lodash";
import axios from "axios";
import Drawer from "@material-ui/core/Drawer";
import Layout from "../components/Layout";
import SearchInput from "../components/SearchInput";
import MarketClosedWarning from "../components/MarketClosedWarning";
import Icon from "../components/Icon";
import ProductDialog from "../components/ProductDialog";
import ProductContainer from "../components/ProductContainer";
import { getCartItemInfo, makeCancellable } from "../utils";
import { BASE_PATH } from "../config";
import ProductCategoryContainer from "../components/ProductCategoryContainer";
import { FOOD_ROUTE } from "../constants";
import UserExitDialog from "../components/UserExitDialog";

const productSortOptions = {
  nameUp: (a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return -1;
    }
    return 1;
  },
  nameDown: (a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return -1;
  },
  priceUp: (a, b) => {
    if (a.sortPrice > b.sortPrice) {
      return 1;
    }
    return -1;
  },
  priceDown: (a, b) => {
    if (a.sortPrice > b.sortPrice) {
      return -1;
    }
    return 1;
  },
};

const categorySortOptions = {
  nameUp: (a, b) => {
    if (a.toLowerCase() > b.toLowerCase()) {
      return -1;
    }
    return 1;
  },
  nameDown: (a, b) => {
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
    return -1;
  },
};

const BREAKPOINT_LARGE = 1150;
const BREAKPOINT_MIDDLE = 930;

const getFeaturedProducts = (products, featuredProducts) => {
  const productObj = products.reduce((a, b) => {
    a[b.node.id] = b.node;
    return a;
  }, {});
  return featuredProducts.map(({ id }) => productObj[id]);
};

const FoodContent = ({
  initProducts,
  addToCart,
  cartItems,
  addSnackbarMessage,
  marketOpen,
  bannerMessage,
  featuredProductData,
  location,
}) => {
  const [products, setProducts] = React.useState(initProducts);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [searchRegEx, setSearchRegEx] = React.useState(null);
  const [productSort, setProductSort] = React.useState("nameDown");
  const [categorySort, setCategorySort] = React.useState("nameDown");
  const [activeProducts, setActiveProducts] = React.useState([]);
  const [screenWidth, setScreenWidth] = React.useState(0);
  const featuredProducts = featuredProductData.enabled
    ? getFeaturedProducts(products, featuredProductData.featuredProducts)
    : [];
  let productLimit = 6;
  if (screenWidth > BREAKPOINT_LARGE) {
    productLimit = 10;
  } else if (
    screenWidth <= BREAKPOINT_LARGE &&
    screenWidth >= BREAKPOINT_MIDDLE
  ) {
    productLimit = 8;
  }

  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
    const handleResize = debounce(() => {
      setScreenWidth(window.innerWidth);
    }, 300);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  React.useEffect(() => {
    const wrappedFunction = makeCancellable(
      axios.get(`${BASE_PATH}/getProducts`)
    );
    wrappedFunction.promise
      .then(({ data }) => {
        const newProducts = products.map((product) => {
          product.node = {
            ...product.node,
            ...data.products[product.node.id],
          };
          return product;
        });
        setProducts(newProducts);
      })
      .catch((err) => console.log(err));
    return () => wrappedFunction.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleProductSortChange(e) {
    setProductSort(e.target.value);
  }
  function handleCategorySortChange(e) {
    setCategorySort(e.target.value);
  }
  function handleClose() {
    setDrawerOpen(false);
  }
  const initCategoryFilters = products.reduce((a, { node }) => {
    a[node.category] = true;
    return a;
  }, {});
  const cartItemInfo = getCartItemInfo(cartItems);
  const [categoryFilters, setCategoryFilters] = React.useState(
    initCategoryFilters
  );
  const filterCount = Object.keys(categoryFilters).reduce((a, b) => {
    if (categoryFilters[b]) {
      a++;
    }
    return a;
  }, 0);
  const filterTotal = Object.keys(categoryFilters).length;
  function setAllFilters(value) {
    const newFilters = { ...categoryFilters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = value;
    });
    setCategoryFilters(newFilters);
  }
  function updateCategoryFilter(key) {
    const newFilters = { ...categoryFilters };
    newFilters[key] = !newFilters[key];
    setCategoryFilters(newFilters);
  }
  let filteredProducts = products.filter(({ node: { category } }) =>
    Boolean(categoryFilters[category])
  );
  if (searchRegEx) {
    filteredProducts = filteredProducts.filter(({ node }) =>
      searchRegEx.test(node.searchTags)
    );
  }
  const categories = filteredProducts.reduce((a, { node }) => {
    if (a.hasOwnProperty(node.category)) {
      a[node.category].push(node);
    } else {
      a[node.category] = [node];
    }
    return a;
  }, {});
  return (
    <>
      {marketOpen === false && <MarketClosedWarning />}
      {marketOpen && bannerMessage && (
        <div className="yellow-alert-msg left-align-mobile">
          <h6>{bannerMessage}</h6>
        </div>
      )}
      <div className="green-heading text-center">
        <h2>FIND FRESH FOOD</h2>
        <SearchInput setSearchRegEx={setSearchRegEx} products={products} />
      </div>
      {featuredProductData.enabled && !searchRegEx && (
        <section className="food-section featured-food-section">
          <div className="layout-container">
            <h3 className="text-center">FEATURED PRODUCTS</h3>
            <span className="divider" />
            <p
              className="text-center"
              style={{ maxWidth: 750, margin: "0 auto 8px" }}
            >
              {featuredProductData.message}
            </p>
            <div className="grid-container add-width-variable">
              {featuredProducts.map((featuredProduct, index) => {
                if (featuredProduct) {
                  return (
                    <div key={`fp-${index}`} className="grid-item-product">
                      <ProductContainer
                        product={featuredProduct}
                        addToCart={addToCart}
                        cartItem={cartItemInfo[featuredProduct.id]}
                        setActiveProduct={(newProduct) =>
                          setActiveProducts([newProduct])
                        }
                        marketOpen={marketOpen}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </section>
      )}
      <div className="layout-container margin-bottom-4">
        {searchRegEx && (
          <p className="display-count">
            Displaying {filteredProducts.length} of {products.length} total
            products
          </p>
        )}
        {Object.keys(categories).length > 0 ? (
          Object.keys(categories)
            .sort(categorySortOptions[categorySort])
            .map((key) => {
              return (
                <section key={key} className="food-section">
                  <div>
                    <h2 key={key} className="category-label">
                      {key}
                    </h2>
                  </div>
                  <ProductCategoryContainer
                    products={categories[key].sort(
                      productSortOptions[productSort]
                    )}
                    addToCart={addToCart}
                    cartItemInfo={cartItemInfo}
                    setActiveProduct={(newProduct) =>
                      setActiveProducts([newProduct])
                    }
                    productLimit={productLimit}
                    categoryName={key}
                    hasSearchCriteria={Boolean(searchRegEx)}
                    marketOpen={marketOpen}
                  />
                </section>
              );
            })
        ) : (
          <p className="text-center add-padding-2">No results to show.</p>
        )}
      </div>
      <button className="btn fab" onClick={() => setDrawerOpen(true)}>
        <Icon width={22} iconName="filter" />
        {filterCount !== filterTotal && (
          <span className="filter-count">{filterCount}</span>
        )}
      </button>
      <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
        <div className="drawer">
          <button className="btn close-btn" onClick={handleClose}>
            <Icon width={18} iconName="times" />
          </button>
          <div className="filter-container">
            <h5 className="text-secondary">Category filter options</h5>
            <p className="caption">
              Showing results for {filterCount} of {filterTotal} categories
            </p>
            <div style={{ borderBottom: "1px solid #dddddd" }}>
              <div className="flex-btn-container">
                <div>
                  <button
                    className="btn white-btn full-width select-btn"
                    onClick={() => setAllFilters(true)}
                  >
                    SELECT ALL
                  </button>
                </div>
                <div>
                  <button
                    className="btn white-btn full-width select-btn"
                    onClick={() => setAllFilters(false)}
                  >
                    DESELECT ALL
                  </button>
                </div>
              </div>
            </div>
            {Object.keys(categoryFilters)
              .sort()
              .map((key) => {
                return (
                  <button
                    className={`btn filter-btn${
                      categoryFilters[key] ? " selected" : ""
                    }`}
                    key={key}
                    onClick={() => updateCategoryFilter(key)}
                  >
                    <div className="icon-container">
                      <Icon iconName="check" width={20} />
                    </div>
                    <div>{key}</div>
                  </button>
                );
              })}
          </div>
          <div className="sort-container">
            <h5 className="text-secondary">Sort options</h5>
            <div>
              <label htmlFor="product-sort">Sort food products by:</label>
              <select
                className="form-input"
                name="product-sort"
                value={productSort}
                onChange={handleProductSortChange}
                onBlur={handleProductSortChange}
              >
                <option value="nameDown">Name (A to Z)</option>
                <option value="nameUp">Name (Z to A)</option>
                <option value="priceUp">Price (low to high)</option>
                <option value="priceDown">Price (high to low)</option>
              </select>
            </div>
            <div>
              <label htmlFor="category-sort">Sort food categories by:</label>
              <select
                className="form-input"
                name="category-sort"
                value={categorySort}
                onChange={handleCategorySortChange}
                onBlur={handleCategorySortChange}
              >
                <option value="nameDown">Name (A to Z)</option>
                <option value="nameUp">Name (Z to A)</option>
              </select>
            </div>
          </div>
        </div>
      </Drawer>
      {typeof window !== "undefined" && (
        <>
          <ProductDialog
            activeProducts={activeProducts}
            setActiveProducts={setActiveProducts}
            products={products}
            cartItems={cartItems}
            addToCart={addToCart}
            addSnackbarMessage={addSnackbarMessage}
            marketOpen={marketOpen}
          />
          <UserExitDialog location={location} marketOpen={marketOpen} />
        </>
      )}
    </>
  );
};

export default ({
  data: {
    marketStatus: { marketOpen, bannerMessage },
    allProduct: { edges: products },
    featuredProducts,
  },
  location,
}) => {
  return (
    <Layout pageTitle="Fresh food" addLayoutProps ogUrl={FOOD_ROUTE}>
      <FoodContent
        initProducts={products}
        marketOpen={marketOpen}
        bannerMessage={bannerMessage}
        featuredProductData={featuredProducts}
        location={location}
      />
    </Layout>
  );
};

export const query = graphql`
  query FoodQuery {
    allProduct(
      filter: { status: { eq: "ACTIVE" }, category: { ne: "Member Store" } }
    ) {
      edges {
        node {
          id
          about
          category
          name
          path
          price
          displayPrice
          priceOption
          sortPrice
          searchTags
          qtyAvailable
          priceOptionItems {
            description
            name
            price
            qtyAvailable
          }
          farm {
            path
            name
          }
          productImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    marketStatus {
      marketOpen
      bannerMessage
    }
    featuredProducts {
      message
      enabled
      featuredProducts {
        id
      }
    }
  }
`;
