import React from 'react';
import ProductContainer from './ProductContainer';


export default ({ hasSearchCriteria, products, addToCart, cartItemInfo, setActiveProduct, productLimit, categoryName, marketOpen }) => {
    const [showMore, setShowMore] = React.useState(false);
    const shouldShowMore = hasSearchCriteria || showMore;
    const shownProducts = products.slice(0, productLimit);
    function renderProduct(product) {
        return (
            <div key={product.id} className='grid-item-product'>
                <ProductContainer
                    product={product} 
                    addToCart={addToCart}
                    cartItem={cartItemInfo[product.id]}
                    setActiveProduct={setActiveProduct}
                    marketOpen={marketOpen}
                />
            </div>
        )
    }
    function handleClick() {
        setShowMore(!showMore);
    }
    function renderAdditionalProducts() {
        let btnText = `Show ${shouldShowMore ? "less" : "all " + products.length} ${categoryName} ${categoryName.slice(-1) === "s" ? "" : " items"}`;
        const productsToShow = shouldShowMore ? products.slice(productLimit) : products.slice(productLimit, productLimit + (productLimit/2));
        if (products.length > productLimit) {
            return (
                <div className={`grid-container add-width-variable more-products-container ${shouldShowMore ? '' : ' hide-products'}`}>
                    {productsToShow.map(product => renderProduct(product))}
                    {!hasSearchCriteria && <div className='overlay'>
                        <button className='btn secondary-outline-btn box-shadow-btn' onClick={handleClick}>{btnText.toUpperCase()}</button>
                    </div>}
                </div>
            )
        }
    }
    return (
        <>
            <div className='grid-container add-width-variable'>
                {shownProducts.map(product => renderProduct(product))}
            </div>
            {renderAdditionalProducts()}
        </>
    )
}