import React from 'react';
import Grow from '@material-ui/core/Grow';
import Icon from './Icon';

const suggestionItems = [
    {
        suggestionLabel: "Product categories",
        suggestionIcon: "tag"
    },
    {
        suggestionLabel: "Local farms",
        suggestionIcon: "farm"
    },
    {
        suggestionLabel: "Popular items", 
        suggestionIcon: "seedling"
    },
];

export default ({ setSearchRegEx, products }) => {
    const [value, setValue] = React.useState('');
    const [focused, setFocused] = React.useState(false);
    const regEx = value ? new RegExp(value.replace(/[|&;$%@"<>()+,\\]/g, (match) => { return `\\${match}` }), 'i') : null;
    React.useEffect(() => {
        if (!focused) {
            setSearchRegEx(regEx);
        }
        // eslint-disable-next-line
    }, [value, focused])
    const ref = React.useRef();
    const filterItems = [];
    const labels = {};
    products.forEach(({ node: { name, farm, category } }) => {
        if (!labels.hasOwnProperty(name)) {
            filterItems.push({ label: name, icon: "seedling" });
            labels[name] = true;
        }
        if (!labels.hasOwnProperty(farm.name)) {
            filterItems.push({ label: farm.name, icon: "farm" });
            labels[farm.name] = true;
        }
        if (!labels.hasOwnProperty(category)) {
            filterItems.push({ label: category, icon: "tag" });
            labels[category] = true; 
        }
    })
    function handleTextChange(event) {
        setValue(event.target.value);
    }
    function handleFocus() {
        setFocused(true);
    }
    function handleBlur() {
        setFocused(false);
    }
    function handleClear() {
        setValue('');
        setSearchRegEx(null);
        ref.current.focus();
        setFocused(true);
    }
    function handleChipClick() {
        ref.current.focus();
    }
    function handleSubmit(event) {
        event.preventDefault();
        ref.current.blur();
    }
    const showChip = Boolean(value && !focused);
    function renderSuggestions() {
        if (regEx) {
            const renderItems = filterItems.filter(({ label }) => regEx.test(label)).sort((a, b) => {
                if (regEx.test(a.label.slice(0, value.length))) {
                    return -1;
                }
                return 1;
            })
            if (renderItems.length > 0) {
                return (
                    <ul>
                        {renderItems.slice(0, 12).map(({ label, icon }) => {
                            function handleClick() {
                                setValue(label)
                            }
                            return (
                                <li key={label}>
                                    <button className="btn grid-btn" onClick={handleClick} type="button">
                                        <span><Icon iconName={icon} width={20} /></span>
                                        <span>{label}</span>
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                )
            }
            return (
                <div><p className="no-results text-muted">No search results</p></div>
            )
        }
        return suggestionItems.map(({ suggestionLabel, suggestionIcon }, index) => {
            return (
                <React.Fragment key={`frag-${index}`}>
                    <h6>{suggestionLabel}</h6>
                    <ul className="margin-bottom-1">
                        {filterItems.filter(({ icon }) => icon === suggestionIcon)
                        .slice(0, 25)
                        .sort((a, b) => {
                            if (a.label.toUpperCase() > b.label.toUpperCase()) {
                                return 1;
                            }
                            return -1;
                        })
                        .map(({ label, icon }) => {
                            function handleClick() {
                                setValue(label);
                            }
                            return (
                                <li key={`${suggestionLabel}-${label}`}>
                                    <button className="btn grid-btn" onClick={handleClick} type="button">
                                        <span><Icon iconName={icon} width={20} /></span>
                                        <span>{label}</span>
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </React.Fragment>
            )
        })
    }
    return (
        <form onSubmit={handleSubmit} action=".">
            <div className={`search-container${focused ? " focused" : ""}`}>
                <div className='input-container'>
                    {value && !focused &&
                        <div className='chip' role="button" onClick={handleChipClick} tabIndex="0" onKeyDown={handleChipClick}>
                            <span>{value}</span>
                            <button className='btn' onClick={handleClear}><Icon width={16} iconName="times" /></button>
                        </div>
                    }                    
                    <input 
                        placeholder="Search for farm fresh..." 
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleTextChange}
                        value={value}
                        ref={ref}
                        autoComplete='off'
                        autoCapitalize='off'
                        autoCorrect='off'
                        type="search"
                        style={{width: showChip ? '0' : '100%'}}
                    />
                    <Grow in={focused}>
                        <div className="suggestions-container">
                            {renderSuggestions()}
                        </div>
                    </Grow>
                </div>
                <div className='icon-container'>
                    <button type="submit" className="btn">
                        <Icon width={26} iconName='search' />
                    </button>
                </div>
            </div>
        </form>
    )
}